.link {
  margin: 0.2rem 1rem;
  text-decoration: none;
  display: flex;
}

.linkButton {
  width: 100%;
  min-height: 2.2rem;
}

.label {
    margin-right: auto;
    margin-left: 0.35rem;
    text-align: left;
}
