.react-calendar {
    width: 100%;
    background: white;
    border: none;
}

.react-calendar__tile {
    max-width: initial !important;
}

.react-calendar__tile--active {
    background: #f8f8f8 !important;
}

.react-calendar__tile--now {
    background: #f0f0f0 !important;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000 !important;
}

.react-calendar__tile.holiday {
    background: #ffcccc !important;
    color: red !important;
    position: relative;
}

.react-calendar__tile.holiday div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.year-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.month-container {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.month-title {
    font-size: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px dotted gray;
}

.react-calendar__tile:hover {
    cursor: default !important;
}

.react-calendar__tile abbr {
    display: none !important;
}
